import * as React from "react"
import {graphql} from "gatsby"
import Typography from '@material-ui/core/Typography';
import Container from "@material-ui/core/Container";
import Grid from "@material-ui/core/Grid";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import CardActions from "@material-ui/core/CardActions";
import {GatsbyImage} from "gatsby-plugin-image";
import Avatar from '@material-ui/core/Avatar';
import Rating from '@material-ui/lab/Rating';
import PersonOutlineOutlinedIcon from '@material-ui/icons/PersonOutlineOutlined';
import LayoutTmpl from "../components/layout";
import ButtonBase from '@material-ui/core/ButtonBase';
import {styled} from '@material-ui/core/styles';
import {Helmet} from "react-helmet";
import Links from '@material-ui/core/Link';
import Chip from "@material-ui/core/Chip";
import Link from "@material-ui/core/Link";
import Tooltip from "@material-ui/core/Tooltip";
import ThumbUpAltIcon from "@material-ui/icons/ThumbUpAlt";
import ThumbDownAltIcon from "@material-ui/icons/ThumbDownAlt";
import CommentIcon from "@material-ui/icons/Comment";

const StyledHeroContentDiv = styled('div')(({theme}) => ({
    backgroundColor: theme.palette.background.paper,
    padding: theme.spacing(2, 0, 1),
}));
const StyledCardGridContainer = styled(Container)(({theme}) => ({
    paddingTop: theme.spacing(3),
    paddingBottom: theme.spacing(8),
}));
const StyledCard = styled(Card)(({theme}) => ({
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
}));
const StyledCardContent = styled(CardContent)(({theme}) => ({
    flexGrow: 1,
}));
const StyledFooterContentDiv = styled('div')(({theme}) => ({
    backgroundColor: theme.palette.background.paper,
    padding: theme.spacing(2, 0, 1),
}));

const ReviewsListPage = ({data}) => {

    const rows = data.allPageReviewsIndex.nodes;
    const images = data.allPageImages.nodes;
    const elements = data.allPageElementsTop.nodes;

    function getComments(reviewUrl) {
        window.location.replace("https://ocenivay.com" + reviewUrl + "?comments");
    }

    return (
        <>

            <Helmet defer={false}>
                <html lang="ru-RU"/>
                <title>Ocenivay.com - отзывы</title>
                <meta name="keywords" content="Ocenivay.com - отзывы"/>
                <meta name="description"
                      content="Найти положительный или отрицательный отзыв, история роста или падения рейтингов о товаре, компании или услуге"/>
                <link rel="canonical" href="https://ocenivay.com"/>
                <meta name="robots" content="index, follow"/>
                <meta name="x-robots-tag" content="all"/>
                <meta property="og:type" content="article"/>
                <meta property="og:site_name" content="Ocenivay.com"/>
                <meta property="og:title" content="Ocenivay.com - отзывы"/>
                <meta property="og:description"
                      content="Найти положительный или отрицательный отзыв, история роста или падения рейтингов о товаре, компании или услуге. Советы сообщества. Читайте отзывы реальных людей! Пишите свои отзывы!"/>
                <meta property="og:url" content="https://ocenivay.com"/>
                <meta property="og:locale" content="ru_RU"/>
                <meta property="og:image" content="https://ocenivay.com/logo.png"/>
                <meta http-equiv="cache-control" content="no-cache"/>
                <meta http-equiv="expires" content="0"/>
                <meta http-equiv="pragma" content="no-cache"/>
            </Helmet>

            <LayoutTmpl>
                <main>
                    {/* Hero unit */}
                    <StyledHeroContentDiv>
                        <Container maxWidth="md">
                            <Typography component="h1" variant="h5" align="left" color="textPrimary" gutterBottom>
                                Отзывы и рейтинги о товарах, услугах, компаниях
                            </Typography>
                            <Typography align="left" color="textSecondary" paragraph>
                                Найти положительный или отрицательный отзыв, история роста или падения рейтингов о
                                товаре, компании или услуге. Советы сообщества. Читайте отзывы реальных людей! Пишите
                                свои
                                отзывы!
                            </Typography>
                        </Container>
                    </StyledHeroContentDiv>
                    <StyledCardGridContainer maxWidth="md">


                        <Grid container spacing={4}>

                            {rows.map((row, i) => {

                                return (

                                    <Grid item key={i} xs={12} sm={6} md={4}>
                                        <StyledCard>
                                            <ButtonBase>
                                                {
                                                    images.map((image) => {

                                                        return (
                                                            (typeof image.localImages[0] !== 'undefined' && image.localImages[0] !== null && image.element === row.element.id ? image.localImages[0].childImageSharp === null ? '' :
                                                                <Links href={row.element.url} key={i}>
                                                                    <GatsbyImage
                                                                        image={image.localImages[0].childImageSharp.gatsbyImageData}
                                                                        alt={row.element.name}/>
                                                                </Links> : "")
                                                        )
                                                    })
                                                }
                                            </ButtonBase>
                                            <StyledCardContent>
                                                <Grid
                                                    container
                                                    direction="row"
                                                    justifyContent="flex-start"
                                                    alignItems="flex-start"
                                                >
                                                    <Grid
                                                        style={{
                                                            padding: 0,
                                                            margin: 0,
                                                        }}
                                                    >
                                                        <Rating name="half-rating-read"
                                                                style={{
                                                                    margin: '0 10px 0 0'
                                                                }}
                                                                value={parseFloat(row.element.reviews[0].rating.number)}
                                                                precision={0.5} readOnly/>
                                                    </Grid>
                                                    <Grid>
                                                        <Typography variant="body2" component="p" color="textPrimary"
                                                                    style={{
                                                                        fontSize: '0.8rem',
                                                                        margin: '3px 0 0 0'
                                                                    }}
                                                        >
                                                            {row.element.reviews[0].rating.text}
                                                        </Typography>
                                                    </Grid>
                                                </Grid>

                                                <Typography gutterBottom variant="body2" component="h1"
                                                            color="textPrimary">
                                                    <Links href={row.element.url}
                                                           style={{
                                                               color: '#000000',
                                                               textShadow: 'none',
                                                               backgroundImage: 'none',
                                                               fontSize: '1.2rem',
                                                               fontWeight: 400
                                                           }}
                                                    >{row.element.name}</Links>
                                                </Typography>

                                                <Typography variant="body2" color="textPrimary" component="p"
                                                            gutterBottom>
                                                    {row.element.reviews[0].text} <Links
                                                    href={row.element.reviews[0].url} style={{
                                                    textDecoration: 'underline',
                                                }}>Читать отзыв</Links>
                                                </Typography>
                                                <Grid
                                                    container
                                                    direction="row"
                                                    justifyContent="flex-start"
                                                    alignItems="flex-end"
                                                >
                                                    {row.element.tags.map((tag, t) => {

                                                        return (
                                                            <Grid key={t}>
                                                                <Typography gutterBottom variant="body2" component="p"
                                                                            style={{
                                                                                marginTop: 10
                                                                            }}
                                                                            color="textPrimary">
                                                                    <Links href={tag.url}
                                                                           style={{
                                                                               color: '#3f51b5',
                                                                               textShadow: 'none',
                                                                               backgroundImage: 'none',
                                                                               fontSize: '0.8rem',
                                                                               margin: '5px'
                                                                           }}
                                                                    >#{tag.name}</Links>
                                                                </Typography>
                                                            </Grid>
                                                        )
                                                    })}
                                                </Grid>
                                            </StyledCardContent>
                                            <CardActions>
                                                <Grid container spacing={3}>
                                                    <Grid item xs={4}>
                                                        <Typography variant="body2" component="p"
                                                                    color="textPrimary"
                                                                    style={{
                                                                        fontSize: '0.8rem',
                                                                        color: '#4caf50',
                                                                        marginLeft: "10px"
                                                                    }}
                                                        >
                                                            <Tooltip title="Отзыв полезен">
                                                                <ThumbUpAltIcon/>
                                                            </Tooltip>
                                                            {parseInt(row.element.reviews[0].vote.plus)}
                                                        </Typography>
                                                    </Grid>
                                                    <Grid item xs={4}>
                                                        <Typography variant="body2" component="p" color="textPrimary"
                                                                    style={{
                                                                        fontSize: '0.8rem',
                                                                        textAlign: "center",
                                                                        cursor: "pointer"
                                                                    }}
                                                                    onClick={() => Promise.resolve(getComments(row.element.reviews[0].url))}
                                                        >
                                                            <Tooltip title="Комментарии"><CommentIcon
                                                                style={{
                                                                    marginRight: '3px',
                                                                }}/></Tooltip>
                                                            {parseInt(row.element.reviews[0].comments)}
                                                        </Typography>
                                                    </Grid>
                                                    <Grid item xs={4}>
                                                        <Typography variant="body2" component="p"
                                                                    color="textPrimary"
                                                                    style={{
                                                                        fontSize: '0.8rem',
                                                                        color: '#f44336',
                                                                        textAlign: "right",
                                                                        marginRight: "10px"
                                                                    }}
                                                        >
                                                            <Tooltip title="Отзыв бесполезен">
                                                                <ThumbDownAltIcon/>
                                                            </Tooltip>
                                                            {parseInt(row.element.reviews[0].vote.minus)}
                                                        </Typography>
                                                    </Grid>
                                                    <Grid item>
                                                        {
                                                            (row.element.reviews[0].user.id > 0 ?
                                                                    (row.element.reviews[0].user.avatar ?
                                                                        <Avatar alt={row.element.reviews[0].user.name}
                                                                                src={row.element.reviews[0].user.avatar}/> :
                                                                        <Avatar
                                                                            alt={row.element.reviews[0].user.name}><PersonOutlineOutlinedIcon/></Avatar>) :
                                                                    <Avatar
                                                                        alt="Anonymous"><PersonOutlineOutlinedIcon/></Avatar>
                                                            )
                                                        }
                                                    </Grid>
                                                    <Grid item xs={"auto"}>
                                                        <Typography
                                                            gutterBottom
                                                            variant="body2"
                                                            component="p"
                                                            color="textPrimary" style={{
                                                            fontSize: '0.8rem',
                                                            margin: "12px 0px 0px -10px"
                                                        }}>
                                                            <Link
                                                                href={"/users?userId=" + row.element.reviews[0].user.id}
                                                                style={{
                                                                    color: '#3f51b5',
                                                                    textShadow: 'none',
                                                                    backgroundImage: 'none',
                                                                }}>{row.element.reviews[0].user.name}</Link>
                                                        </Typography>
                                                    </Grid>
                                                </Grid>
                                            </CardActions>

                                        </StyledCard>
                                    </Grid>

                                )
                            })}

                        </Grid>

                    </StyledCardGridContainer>
                </main>

                <main>
                    <StyledFooterContentDiv>
                        <Container maxWidth="md">
                            <Typography component="h1" variant="h5" align="left" color="textPrimary"
                                        gutterBottom>
                                Популярные отзывы
                            </Typography>
                            <Typography align="left" color="textSecondary" paragraph>
                                Отзывы, которые чаще все читают и комментируют.
                            </Typography>
                        </Container>
                    </StyledFooterContentDiv>
                    <StyledCardGridContainer maxWidth="md">

                        <Grid container spacing={4}>

                            {elements.map((row, i) => {

                                return (

                                    <Grid item key={i} xs={12} sm={6} md={4}>
                                        <StyledCard>
                                            <ButtonBase>
                                                {
                                                    images.map((image) => {

                                                        return (
                                                            (image.element === row.element.id ? image.localImages[0].childImageSharp === null ? '' :
                                                                <Links href={row.element.url} key={i}>
                                                                    <GatsbyImage
                                                                        image={image.localImages[0].childImageSharp.gatsbyImageData}
                                                                        alt={row.element.name}/>
                                                                </Links> : "")
                                                        )
                                                    })
                                                }
                                            </ButtonBase>

                                            <StyledCardContent>
                                                <Grid
                                                    container
                                                    direction="row"
                                                    justifyContent="flex-start"
                                                    alignItems="flex-start"
                                                >
                                                    <Grid
                                                        style={{
                                                            padding: 0,
                                                            margin: 0,
                                                        }}
                                                    >
                                                            <Rating name="half-rating-read"
                                                                    style={{
                                                                        margin: '0 10px 0 0'
                                                                    }}
                                                                    value={parseFloat(row.element.rating)}
                                                                    precision={0.5} readOnly/>
                                                    </Grid>
                                                    <Grid>
                                                        <Typography variant="body2" component="div"
                                                                    color="textPrimary"
                                                                    style={{
                                                                        fontSize: '0.8rem',
                                                                        margin: '3px 0 0 0'
                                                                    }}
                                                        >
                                                            <Links href={row.element.url}
                                                            >Рейтинг {
                                                                ((parseFloat(row.element.rating) < 3) &&
                                                                    <Chip
                                                                        size="small"
                                                                        label={parseFloat(row.element.rating)}
                                                                        style={{
                                                                            width: "2.7rem",
                                                                            height: "1rem",
                                                                            fontSize: '0.8rem',
                                                                            padding: '1px 0 0 0',
                                                                            margin: '-3px 0 0 0',
                                                                            backgroundColor: 'red',
                                                                            color: 'white',
                                                                        }}/>
                                                                )
                                                            }
                                                                {
                                                                    ((parseFloat(row.element.rating) >= 3 && parseFloat(row.element.rating) < 4) &&
                                                                        <Chip
                                                                            size="small"
                                                                            label={parseFloat(row.element.rating)}
                                                                            style={{
                                                                                width: "2.7rem",
                                                                                height: "1rem",
                                                                                fontSize: '0.8rem',
                                                                                padding: '1px 0 0 0',
                                                                                margin: '-3px 0 0 0',
                                                                                backgroundColor: '#F3DD10FF',
                                                                                color: 'black',
                                                                            }}/>
                                                                    )
                                                                }
                                                                {
                                                                    ((parseFloat(row.element.rating) >= 4) &&
                                                                        <Chip
                                                                            size="small"
                                                                            label={parseFloat(row.element.rating)}
                                                                            style={{
                                                                                width: "2.7rem",
                                                                                height: "1rem",
                                                                                fontSize: '0.8rem',
                                                                                padding: '1px 0 0 0',
                                                                                margin: '-3px 0 0 0',
                                                                                backgroundColor: 'green',
                                                                                color: 'white',
                                                                            }}/>
                                                                    )
                                                                } на
                                                                основе {row.element.reviews_count_number} {row.element.reviews_count_text}</Links>
                                                        </Typography>
                                                    </Grid>
                                                </Grid>

                                                <Typography gutterBottom variant="body2" component="h1"
                                                            color="textPrimary">
                                                    <Links href={row.element.url}
                                                           style={{
                                                               color: '#000000',
                                                               textShadow: 'none',
                                                               backgroundImage: 'none',
                                                               fontSize: '1.2rem',
                                                               fontWeight: 400
                                                           }}
                                                    >{row.element.name}</Links>
                                                </Typography>
                                                <Typography variant="body2" color="textPrimary"
                                                            component="p"
                                                            gutterBottom>
                                                    {row.element.text_short} <Links href={row.element.url}
                                                                                    style={{
                                                                                        textDecoration: 'underline',
                                                                                    }}>Все отзывы</Links>
                                                </Typography>
                                                <Grid
                                                    container
                                                    direction="row"
                                                    justifyContent="flex-start"
                                                    alignItems="flex-end"
                                                >
                                                    {row.element.tags.map((tag, t) => {

                                                        return (
                                                            <Grid key={t}>
                                                                <Typography gutterBottom variant="body2"
                                                                            component="p"
                                                                            style={{
                                                                                marginTop: 10
                                                                            }}
                                                                            color="textPrimary">
                                                                    <Links href={tag.url}
                                                                           style={{
                                                                               color: '#3f51b5',
                                                                               textShadow: 'none',
                                                                               backgroundImage: 'none',
                                                                               fontSize: '0.8rem',
                                                                               margin: '5px'
                                                                           }}
                                                                    >#{tag.name}</Links>
                                                                </Typography>
                                                            </Grid>
                                                        )
                                                    })}
                                                </Grid>
                                            </StyledCardContent>
                                        </StyledCard>
                                    </Grid>

                                )
                            })}

                        </Grid>

                    </StyledCardGridContainer>
                </main>

            </LayoutTmpl>

        </>
    )
}

export default ReviewsListPage

export const pageQuery = graphql`
  query IndexReviewsQuery {
    site {
      siteMetadata {
        title
      }
    }
  allPageImages {
    nodes {
      element
      localImages {
        childImageSharp {
          gatsbyImageData(
            placeholder: BLURRED
            formats: [AUTO, WEBP, AVIF]
            width: 537
            height: 382
          )
        }
      }
    }
  }
  allPageElementsTop {
    nodes {
      element {
          url
          text_short
          tags {
            url
            name
          }
          rating
          reviews_count_text
          reviews_count_number
          name
          id
      }
    }
  }
  allPageReviewsIndex {
    nodes {
      element {
        id
        name
        rating
        reviews {
          date
          elementId
          getlastmod
          id
          rating {
            number
            text
          }
          text
          text_minus
          text_comment
          text_plus
          text_preview
          url
          user {
            avatar
            id
            name
          }
          vote {
            minus
            plus
          }
          comments
        }
        reviews_count_number
        reviews_count_text
        tags {
          name
          url
        }
        text
        text_short
        url
      }
      id
    }
  }
 }
`
